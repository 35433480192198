<template>
  <svg
    id="icon_connections"
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="28"
    viewBox="0 0 21 28"
  >
    <path
      id="Icon_awesome-plug"
      data-name="Icon awesome-plug"
      d="M14,7.875V1.75a1.75,1.75,0,1,1,3.5,0V7.875Zm6.125.875H.875A.875.875,0,0,0,0,9.625v1.75a.875.875,0,0,0,.875.875H1.75V14a8.753,8.753,0,0,0,7,8.575V28h3.5V22.575a8.753,8.753,0,0,0,7-8.575V12.25h.875A.875.875,0,0,0,21,11.375V9.625A.875.875,0,0,0,20.125,8.75ZM7,7.875V1.75a1.75,1.75,0,1,0-3.5,0V7.875Z"
      fill="#fff"
    />
  </svg>
</template>