<template>
  <svg
    id="icon_home"
    xmlns="http://www.w3.org/2000/svg"
    width="29.25"
    height="27"
    viewBox="0 0 29.25 27"
  >
    <path
      id="Icon_ionic-md-home"
      data-name="Icon ionic-md-home"
      d="M14.625,31.5v-9h6.75v9h6.862V18h4.388L18,4.5,3.375,18H7.762V31.5Z"
      transform="translate(-3.375 -4.5)"
      fill="#fff"
    />
  </svg>
</template>