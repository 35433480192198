<template>
  <svg
    id="icon_quicksight"
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="25.411"
    viewBox="0 0 27 25.411"
  >
    <path
      id="Icon_awesome-chart-pie"
      data-name="Icon awesome-chart-pie"
      d="M26.2,14.294H14.418l7.843,7.843a.8.8,0,0,0,1.1.034,11.887,11.887,0,0,0,3.63-6.991.8.8,0,0,0-.8-.886Zm-.786-3.216A11.94,11.94,0,0,0,14.334,0a.8.8,0,0,0-.834.8V11.912H24.606a.8.8,0,0,0,.8-.834ZM11.118,14.294V2.517a.8.8,0,0,0-.885-.8A11.9,11.9,0,0,0,.007,13.915a12.059,12.059,0,0,0,12.055,11.5,11.819,11.819,0,0,0,6.713-2.185.789.789,0,0,0,.078-1.2Z"
      transform="translate(0 0)"
      fill="#fff"
    />
  </svg>
</template>