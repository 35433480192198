<template>
  <component
    :is="iconComponents[props.icon]"
  />
</template>
<script lang="ts" setup>
// import AdministrationIcon from '@/components/icons/AdministrationIcon.vue';
import AffinityPartners from '@/components/icons/AffinityPartners.vue';
// import AssetUpload from '@/components/icons/AssetUpload.vue';
import ConfigurationIcon from '@/components/icons/ConfigurationIcon.vue';
import ConnectionsIcon from '@/components/icons/ConnectionsIcon.vue';
import CreateIcon from '@/components/icons/CreateIcon.vue';
import Home from '@/components/icons/Home.vue';
import AnalyticsDashboards from '@/components/icons/AnalyticsDashboards.vue';
import Taxonomy from '@/components/icons/Taxonomy.vue';
// import TenantMetrics from '@/components/icons/TenantMetrics.vue';
import Users from '@/components/icons/Users.vue';
import CustomerEngagement from '@/components/icons/CustomerEngagement.vue';

const props = defineProps({
  class: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
});

const iconComponents: any = {
  Home,
  'Affinity Partners': AffinityPartners,
  Configurations: ConfigurationIcon,
  Connections: ConnectionsIcon,
  Create: CreateIcon,
  'Analytics Dashboards': AnalyticsDashboards,
  Taxonomy,
  Users,
  'Customer Engagement': CustomerEngagement,
};
</script>
