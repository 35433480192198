export const formatDateTimeWithPeriod = (value: number) => {
  const [, tzName]: any = /.*\s(.+)/.exec(new Date(value).toLocaleDateString(undefined, { timeZoneName: 'short' }));
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateTimeFormat = new Intl.DateTimeFormat('default', {
    day: '2-digit',
    month: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: timeZone,
  });

  const [
    { value: month },
    { value: day },
    { value: hour },
    { value: minute },
    { value: dayPeriod },
  ] = dateTimeFormat.formatToParts(value).filter((x) => x.type !== 'literal');

  const formattedDate = `${month}/${day} @ ${hour}:${minute}${dayPeriod} ${tzName}`;

  return formattedDate;
};