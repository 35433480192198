<template>
  <svg
    id="icon_users"
    xmlns="http://www.w3.org/2000/svg"
    width="35.764"
    height="19.848"
    viewBox="0 0 35.764 19.848"
  >
    <g
      id="Group_4961"
      data-name="Group 4961"
      transform="translate(-1.959 -2.902)"
    >
      <path
        id="Path_6564"
        data-name="Path 6564"
        d="M275.926,330.4h-5.673A4.418,4.418,0,0,0,266,335a1.453,1.453,0,0,0,1.418,1.533h11.345A1.453,1.453,0,0,0,280.181,335a4.418,4.418,0,0,0-4.254-4.6Z"
        transform="translate(-253.067 -313.782)"
        fill="#fff"
      />
      <path
        id="Path_6565"
        data-name="Path 6565"
        d="M304.49,207.409a4.868,4.868,0,0,0,4.888-4.888v-1.63a4.888,4.888,0,0,0-9.776,0v1.63A4.868,4.868,0,0,0,304.49,207.409Z"
        transform="translate(-284.467 -193.102)"
        fill="#fff"
      />
      <path
        id="Path_6566"
        data-name="Path 6566"
        d="M420.765,187.754a3.59,3.59,0,0,0,3.565-3.565v-1.426a3.565,3.565,0,1,0-7.129,0v1.426A3.59,3.59,0,0,0,420.765,187.754Z"
        transform="translate(-390.086 -175.872)"
        fill="#fff"
      />
      <path
        id="Path_6567"
        data-name="Path 6567"
        d="M202.365,187.754a3.59,3.59,0,0,0,3.565-3.565v-1.426a3.565,3.565,0,1,0-7.129,0v1.426A3.59,3.59,0,0,0,202.365,187.754Z"
        transform="translate(-193.997 -175.872)"
        fill="#fff"
      />
      <path
        id="Path_6568"
        data-name="Path 6568"
        d="M178,298.843a.519.519,0,0,0,.166-.938,4.562,4.562,0,0,0-3.035-1.1H170.16a4.958,4.958,0,0,0-4.966,5.022,1.649,1.649,0,0,0,1.656,1.6h6.07a1.132,1.132,0,0,0,1.049-.717A6.368,6.368,0,0,1,178,298.843Z"
        transform="translate(-163.234 -283.494)"
        fill="#fff"
      />
      <path
        id="Path_6569"
        data-name="Path 6569"
        d="M409.409,296.8h-4.966a4.819,4.819,0,0,0-3.035,1.049.549.549,0,0,0,.165.938A6.322,6.322,0,0,1,405.6,302.7a1.079,1.079,0,0,0,1.049.717h6.07a1.65,1.65,0,0,0,1.655-1.6,4.958,4.958,0,0,0-4.966-5.022Z"
        transform="translate(-376.654 -283.493)"
        fill="#fff"
      />
    </g>
  </svg>
</template>