<template>
  <svg
    id="icon_create"
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="27"
    viewBox="0 0 27 27"
  >
    <rect
      id="Rectangle_2480"
      data-name="Rectangle 2480"
      width="27"
      height="27"
      rx="5"
      fill="#fff"
    />
    <path
      id="Path_6562"
      data-name="Path 6562"
      d="M18,12V24"
      transform="translate(-4.5 -4.5)"
      fill="none"
      stroke="#2b427d"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="3"
    />
    <path
      id="Path_6563"
      data-name="Path 6563"
      d="M12,18H24"
      transform="translate(-4.5 -4.5)"
      fill="none"
      stroke="#2b427d"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="3"
    />
  </svg>
</template>