<template>
  <svg
    id="icon_affinity_partner"
    xmlns="http://www.w3.org/2000/svg"
    width="27.107"
    height="27.111"
    viewBox="0 0 27.107 27.111"
  >
    <path
      id="Icon_open-browser"
      data-name="Icon open-browser"
      d="M1.151,0A1.694,1.694,0,0,0,0,1.692V25.408A1.694,1.694,0,0,0,1.693,27.1H25.409A1.694,1.694,0,0,0,27.1,25.408V1.692A1.694,1.694,0,0,0,25.409,0H1.693a1.694,1.694,0,0,0-.3,0,1.694,1.694,0,0,0-.2,0Zm3.93,3.388A1.694,1.694,0,1,1,3.387,5.08,1.678,1.678,0,0,1,5.081,3.386Zm6.776,0H22.021a1.694,1.694,0,1,1,0,3.388H11.857a1.694,1.694,0,1,1,0-3.388Zm-8.47,6.776H23.715V23.714H3.387Z"
      transform="translate(0.003 0.009)"
      fill="#fff"
    />
  </svg>
</template>